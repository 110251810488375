/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Label, Select } from "@src/common/components";
import React, { useContext, useEffect, useState } from "react";
import {
  EndCustomer,
  FullUpdateEndCustomerMutation,
  InvoicingFrequency,
  PricingList,
  useFullUpdateEndCustomerMutation,
  useGetPricingListsLazyQuery,
} from "@api/graphql/generated/generated-types";
import { OnChangeParams } from "baseui/select";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddressInput from "@src/orders/create/components/AddressInput";
import { formatPhoneNumber } from "@src/common/lib/textUtils";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { ApolloError, FetchResult } from "@apollo/client";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useCourieStore } from "@src/common/lib/store";

type Props = {
  endCustomer: EndCustomer | null | undefined;
  onSuccess?: (res: FetchResult<FullUpdateEndCustomerMutation>) => void;
  onCancel?: () => void;
  maxWidth?: number | string;
};

const INVOCING_FREQUENCY_OPTIONS = [
  {
    id: InvoicingFrequency.Daily,
    label: "Daily",
  },
  {
    id: InvoicingFrequency.Weekly,
    label: "Weekly",
  },
  {
    id: InvoicingFrequency.Biweekly,
    label: "Biweekly",
  },
  {
    id: InvoicingFrequency.SemiMonthly,
    label: "Semi-monthly",
  },
  {
    id: InvoicingFrequency.Monthly,
    label: "Monthly",
  },
  {
    id: InvoicingFrequency.PerOrder,
    label: "Invoice for each order",
  },
];

const schema = yup.object().shape({
  contactEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  contactPhoneNumber: yup.string(),
  companyName: yup.string().required("Company Name is required"),
  firstName: yup.string(),
  lastName: yup.string(),
  suite: yup.string(),
  googlePlaceId: yup.string(),
  streetAddress: yup.string(),
  invoicingFrequency: yup.string(),
  taxCode: yup.string().nullable(),
});

function EditCustomerForm({
  endCustomer,
  onSuccess,
  onCancel,
  maxWidth = "100%",
}: Props) {
  // contexts
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // states
  const [priceListSelectValue, setPriceListSelectValue] =
    useState<PricingList | undefined>();
  // gql
  const [getPricingList, { data: PricingListData }] =
    useGetPricingListsLazyQuery();
  const [fullUpdateEndCustomerMutation, { data, loading, error }] =
    useFullUpdateEndCustomerMutation();
  // useeffects
  useEffect(() => {
    if (endCustomer) {
      setPriceListSelectValue(
        endCustomer.pricingList
          ? (endCustomer.pricingList as PricingList)
          : undefined
      );
    }
    if (endCustomer && control) {
      reset({
        companyName: endCustomer.companyName || "",
        contactPhoneNumber: endCustomer.contactPhoneNumber || "",
        contactEmail: endCustomer.contactEmail || "",
        firstName: endCustomer.firstName || "",
        lastName: endCustomer.lastName || "",
        suite: endCustomer.suite || "",
        googlePlaceId: endCustomer?.location?.googlePlaceId || "",
        streetAddress:
          (endCustomer?.location?.streetAddress &&
            `${endCustomer?.location?.streetAddress}, ${endCustomer?.location?.city}`) ||
          "",
        invoicingFrequency:
          endCustomer.invoicingFrequency || InvoicingFrequency.Daily,
        taxCode: endCustomer.taxCode || "",
      });
    }
  }, [endCustomer, setValue]);

  useEffect(() => {
    if (courierId) getPricingList({ variables: { courierId } });
  }, [courierId]);

  const onSubmit = async (data: any) => {
    if (endCustomer) {
      const variables = {
        id: endCustomer.id,
        companyName: data.companyName,
        contactPhoneNumber: data.contactPhoneNumber || "",
        contactEmail: data.contactEmail,
        firstName: data.firstName,
        lastName: data.lastName,
        pricingListId: priceListSelectValue?.id,
        suite: data.suite,
        googlePlaceId: data.googlePlaceId,
        invoicingFrequency: data.invoicingFrequency as InvoicingFrequency,
        taxCode: data.taxCode || null,
      };
      Object.keys(variables).forEach((key) => {
        if (key === "contactPhoneNumber") return;
        if (variables[key] === "") {
          variables[key] = null;
        }
      });
      fullUpdateEndCustomerMutation({
        variables,
      })
        .then((res) => {
          showToast({
            message: "End Customer updated successfully",
            type: "success",
          });
          if (onSuccess) {
            onSuccess(res);
          }
        })
        .catch((err) => {
          showErrorToast(err as ApolloError, showToast);
        });
    }
  };

  const streetAddress = watch("streetAddress");
  const watchInvoicingFrequency = watch("invoicingFrequency");

  function getPricingListValue(pricingList: PricingList | undefined) {
    if (!pricingList) return undefined;
    return [{ id: pricingList.id, label: pricingList.name }];
  }

  const handleAddressChange = (
    address: google.maps.places.AutocompletePrediction | undefined
  ) => {
    if (address) {
      setValue("googlePlaceId", address?.place_id);
      setValue("streetAddress", address?.description);
    } else {
      setValue("googlePlaceId", "");
      setValue("streetAddress", "");
    }
  };

  const atLeastOneValid = (
    value1: string | undefined,
    value2: string | undefined,
    value3: string | undefined
  ) => {
    return !!(value1 || value2 || value3);
  };

  const validateAtLeastOne = () => {
    const companyName = getValues("companyName");
    const firstName = getValues("firstName");
    const lastName = getValues("lastName");
    return (
      atLeastOneValid(companyName, firstName, lastName) ||
      "At least one input should be valid"
    );
  };

  const transformedPricingLists =
    PricingListData?.pricingLists?.map((item) => ({
      id: item.id,
      label: item.name,
    })) || [];

  const handlePriceListSelectChange = (params: OnChangeParams) => {
    if (!params.option || params.option.id === '') {
      setPriceListSelectValue(undefined);
      return;
    }
    const selectedPriceList = PricingListData?.pricingLists?.find(
      (item) => item.id === params.option?.id
    );
    if (selectedPriceList) {
      setPriceListSelectValue(selectedPriceList);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-2 space-y-2 w-full"
      style={{ maxWidth }}
    >
      <div>
        <Label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          Company Name
        </Label>
        {errors.companyName && (
          <span className="text-red-500 text-xs ml-2 truncate">
            *At least one Company name, First or Last name should be valid
          </span>
        )}
        <Controller
          control={control}
          name="companyName"
          rules={{ validate: validateAtLeastOne }}
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
              error={errors.companyName ? true : false}
            />
          )}
        />
      </div>

      <div>
        <Label
          htmlFor="contactEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Contact Email
          {errors.contactEmail && (
            <span className="text-red-500 text-xs ml-2">
              {errors.contactEmail?.message?.toString()}
            </span>
          )}
        </Label>
        <Controller
          control={control}
          name="contactEmail"
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
              error={errors.contactEmail ? true : false}
            />
          )}
        />
      </div>

      <div>
        <Label
          htmlFor="contactPhoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Contact Phone Number
          {errors.contactPhoneNumber && (
            <span className="text-red-500 text-xs ml-2">
              {errors.contactPhoneNumber?.message?.toString()}
            </span>
          )}
        </Label>
        <Controller
          control={control}
          name="contactPhoneNumber"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value && formatPhoneNumber(field.value)}
              onBlur={field.onBlur}
              placeholder=""
              onChange={(event) => {
                event.target.value = formatPhoneNumber(event.target.value);
                field.onChange(event);
              }}
              error={errors.contactPhoneNumber ? true : false}
            />
          )}
        />
      </div>
      <div>
        <Label className="block text-sm font-medium text-gray-700">
          Company Address
        </Label>
        <AddressInput
          handleAddressChange={handleAddressChange}
          control={control}
          size="mini"
          value={streetAddress}
        />
      </div>
      <div>
        <Label className="block text-sm font-medium text-gray-700">Suite</Label>
        <Controller
          control={control}
          name="suite"
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
            />
          )}
        />
      </div>
      <div>
        <Label
          htmlFor="firstName"
          className="block text-sm font-medium text-gray-700"
        >
          First Name
        </Label>
        <Controller
          control={control}
          name="firstName"
          rules={{ validate: validateAtLeastOne }}
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
              error={errors.firstName ? true : false}
            />
          )}
        />
      </div>
      <div>
        <Label
          htmlFor="lastName"
          className="block text-sm font-medium text-gray-700"
        >
          Last Name
        </Label>
        <Controller
          name="lastName"
          rules={{ validate: validateAtLeastOne }}
          control={control}
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
              error={errors.lastName ? true : false}
            />
          )}
        />
      </div>
      <div>
        <Label>Price List</Label>
        <Select
          size="mini"
          options={[
            { id: '', label: 'Base Pricing List' },
            ...transformedPricingLists
          ]}
          value={priceListSelectValue?.id ? getPricingListValue(priceListSelectValue) : [{ id: '', label: 'Base Pricing List' }]}
          searchable={false}
          clearable={false}
          placeholder="Select Price List"
          onChange={handlePriceListSelectChange}
        />
      </div>

      <div>
        <Label>Invoice Frequency</Label>
        <Select
          deleteRemoves={false}
          size="mini"
          escapeClearsValue={false}
          clearable={false}
          options={INVOCING_FREQUENCY_OPTIONS}
          value={
            [
              INVOCING_FREQUENCY_OPTIONS.find(
                (item) => (item.id as string) === watchInvoicingFrequency
              ) || INVOCING_FREQUENCY_OPTIONS[0],
            ] as any
          }
          searchable={false}
          placeholder="Invoice Frequency"
          onChange={(options) => {
            if (!options || options.value.length === 0) return;
            setValue(
              "invoicingFrequency",
              options.value[0]?.id as InvoicingFrequency
            );
          }}
        />
      </div>
      <div>
        <Label
          htmlFor="taxCode"
          className="block text-sm font-medium text-gray-700"
        >
          Tax Code
        </Label>
        <Controller
          control={control}
          name="taxCode"
          render={({ field }) => (
            <Input
              size="mini"
              value={field.value as string | number | undefined}
              onChange={(event) => field.onChange(event.currentTarget.value)}
              onBlur={field.onBlur}
              type="text"
              placeholder=""
            />
          )}
        />
      </div>
      <hr className="my-3" />
      <div className="flex justify-end">
        {onCancel && (
          <Button
            className="mr-4 border"
            color={"secondary"}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" isProcessing={loading}>
          Save
        </Button>
      </div>
    </form>
  );
}

export default EditCustomerForm;
